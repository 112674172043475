import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import BaseLayout from '../../layouts/BaseLayout';
import Login from '../../routes/Login';
import historyContext from '../../context/historyContext';
import ProtectedRoute from '../ProtectedRoute';

import 'fontsource-roboto';

const theme = createMuiTheme({});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={historyContext}>
        <Switch>
          <Route path="/login" component={Login} />
          <ProtectedRoute
            exact
            path={[
              '/solditems',
              '/deliveryoperation',
              '/subscriptions',
              '/reports',
              '/products',
              '/emails',
            ]}
            component={BaseLayout}
          />
          <ProtectedRoute path="/" component={() => <Redirect to="/deliveryoperation" />} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
