function getTableOptions(orderBy, customToolbar) {
  return {
    pagination: false,
    download: false,
    customToolbar,
    selectableRows: false,
    searchPlaceholder: 'Digite o que deseja buscar',
    viewColumns: false,
    filterType: 'dropdown',
    responsive: 'simple',
    print: false,
    setTableProps: () => ({ size: 'small' }),
    sortOrder: {
      name: orderBy(),
      direction: 'asc',
    },
    textLabels: {
      body: {
        noMatch: 'Desculpa, nenhum resultado foi encontrado :(',
        toolTip: 'Ordenação',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima',
        previous: 'Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'View Columns',
        filterTable: 'Filtrar',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'LIMPAR',
      },
      selectedRows: {
        text: 'linha(s) selecionada(s)',
        delete: 'Deletar',
        deleteAria: 'Deletar linhas selecionadas',
      },
    },
  };
}

export default getTableOptions;
