import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

function CustomToolbar({ downLoadTooltip, downloadOnClick, uploadTooltip, uploadOnClick }) {
  return (
    <>
      <Tooltip title={downLoadTooltip}>
        <IconButton onClick={downloadOnClick}>
          <CloudDownloadIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={uploadTooltip}>
        <IconButton onClick={uploadOnClick}>
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

CustomToolbar.propTypes = {
  downloadOnClick: PropTypes.func.isRequired,
  downLoadTooltip: PropTypes.string.isRequired,
  uploadOnClick: PropTypes.func.isRequired,
  uploadTooltip: PropTypes.string.isRequired,
};

export default CustomToolbar;
