import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import CompleteOrder from './CompleteOrder';
import MergeOrder from './MergeOrder';
import SendEmail from './SendEmail';
import UploadRoute from './UploadRoute';

import filesaver from '../../utils/filesaver';
import filereader from '../../utils/filereader';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  select: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const steps = ['Juntar Pedidos', 'Gerar Rotas', 'Enviar Emails', 'Completar Pedidos'];

function DeliveryOperation({ displaySnackbar }) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);

  const [selectedOperationId, setSelectedOperationId] = React.useState('');
  const [operations, setOperations] = React.useState([]);
  const [mergeOrders, setMergeOrders] = React.useState([]);

  const [orders, setOrders] = React.useState([]);

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const prevSelectedOperationRef = React.useRef();

  function getOperation(id) {
    return operations.find((o) => o.id === id);
  }

  const handleReportMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReportMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const getDeliveryOperation = async () => {
      try {
        const response = await axios.get('deliveryoperation');

        const currentOperation = response.data.find((o) => o.status > 0 && o.status < 5);
        const nextOperation =
          currentOperation || response.data.find((o) => o.purchaseClosedAt === null);

        setOperations(response.data);
        setSelectedOperationId(nextOperation.id);
      } catch (error) {
        displaySnackbar(error.message);
      }
    };

    getDeliveryOperation();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const selectedOperation = operations.find((o) => o.id === selectedOperationId);
        const prevSelectedOperation = prevSelectedOperationRef.current;

        if (selectedOperation) {
          setLoading(true);

          const promises = [];

          if (!prevSelectedOperation || selectedOperation.id !== prevSelectedOperation.id) {
            promises.push({
              promise: axios.get(`order/${selectedOperationId}`),
              callback: (response) => setOrders(response.data),
            });
          }

          if (selectedOperation.status === 1) {
            promises.push({
              promise: axios.get(`operationaddress/checksamecustomer/${selectedOperationId}`),
              callback: (response) => setMergeOrders(response.data),
            });
          }

          const responses = await Promise.all(promises.map((p) => p.promise));

          for (let i = 0; i < promises.length; i += 1) {
            promises[i].callback(responses[i]);
          }
        }

        prevSelectedOperationRef.current = selectedOperation;

        setLoading(false);
      } catch (error) {
        let errorMessage = typeof(error.message) != undefined ? error.message : 'Erro na comunicação com a api';
        displaySnackbar(errorMessage);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedOperationId, operations]);

  const handleOperationChange = (event) => {
    setSelectedOperationId(event.target.value);
  };

  const handleMerge = async (selected) => {
    setLoading(true);

    try {
      const response = await axios.put(
        `deliveryoperation/mergeorders/${selectedOperationId}`,
        selected,
      );

      const newOperations = operations.map((operation) => {
        if (operation.id === selectedOperationId) {
          return { ...operation, status: response.data.status };
        }

        return operation;
      });

      setOperations(newOperations);
    } catch (error) {
      displaySnackbar(error.message);
    }

    setLoading(false);
  };

  const handleUploadRoute = async (files) => {
    setLoading(true);

    const formData = new FormData();
    files.forEach((f) => formData.append('file', f));

    const response = await axios.post(`driverroute/upload/${selectedOperationId}`, formData, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.type === 'application/json') {
      const file = await filereader(response.data);
      const { errors } = JSON.parse(file);

      displaySnackbar(errors);
    } else {
      const newOperations = operations.map((operation) => {
        if (operation.id === selectedOperationId) {
          return { ...operation, status: operation.status + 1 };
        }

        return operation;
      });

      setOperations(newOperations);
      filesaver(response);
    }

    setLoading(false);
  };

  const handleCompleteOrder = async (selected) => {
    setLoading(true);

    const response = await axios.post(`order/complete/${selectedOperationId}`, selected);

    const newOrders = orders.map((current) => {
      const item = selected.find((id) => id === current.orderId);

      if (!item) {
        return current;
      }

      return {
        ...current,
        status: 4,
      };
    });

    const newOperations = operations.map((operation) => {
      if (operation.id === selectedOperationId) {
        return { ...operation, status: response.data.status };
      }

      return operation;
    });

    setOperations(newOperations);
    setOrders(newOrders);
    setLoading(false);
  };

  const handleSendEmails = async () => {
    setLoading(true);

    try {
      const response = await axios.get('schedule/sendemails');

      const newOperations = operations.map((operation) => {
        if (operation.id === selectedOperationId) {
          return { ...operation, status: response.data.status };
        }

        return operation;
      });

      setOperations(newOperations);
    } catch (error) {
      displaySnackbar(error.message);
    }

    setLoading(false);
  };

  const handleDownloadClick = (url) => async () => {
    setAnchorEl(null);
    setLoading(true);

    const response = await axios.get(url, { responseType: 'blob' });

    if (response.data.type === 'application/json') {
      const file = await filereader(response.data);
      const { errors } = JSON.parse(file);

      displaySnackbar(errors);
    } else {
      filesaver(response);
    }

    setLoading(false);
  };

  const handleCloseOperationConfirm = async () => {
    setOpenConfirmDialog(false);
    setLoading(true);

    try {
      const response = await axios.put('deliveryoperation/closeoperation');

      setOperations(response.data);
    } catch (error) {
      displaySnackbar(error.message);
    }

    setLoading(false);
  };

  const selectedOperation = getOperation(selectedOperationId);
  const ordersWithNotes = orders.filter((o) => o.notes);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box marginRight={2} marginBottom={2} marginLeft={2}>
        <Paper elevation={2}>
          <Box p={2}>
            <Box mb={2}>
              <FormControl>
                <InputLabel id="operation-label" shrink>
                  Operação
                </InputLabel>
                <Select
                  labelId="operation-label"
                  id="operation-select"
                  value={selectedOperationId}
                  onChange={handleOperationChange}
                >
                  {operations.map((o) => (
                    <MenuItem key={o.id} value={o.id}>
                      {o.operation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button
                aria-controls="report-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownIcon />}
                onClick={handleReportMenuClick}
              >
                Relatórios
              </Button>
              {selectedOperation && (
                <Menu
                  id="report-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleReportMenuClose}
                  elevation={1}
                >
                  {selectedOperation.status > 0 && (
                    <MenuItem
                      onClick={handleDownloadClick(`SoldItems/Download/${selectedOperationId}`)}
                    >
                      Conferência de Itens
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={handleDownloadClick(`PurchaseItems/Download/${selectedOperationId}`)}
                  >
                    {selectedOperation.status === 0 ? 'Prévia' : 'Lista de Compras'}
                  </MenuItem>
                  <MenuItem
                    onClick={handleDownloadClick(
                      `OperationAddress/Download/${selectedOperationId}`,
                    )}
                  >
                    Lista de Endereços
                  </MenuItem>
                </Menu>
              )}
              {selectedOperation && selectedOperation.status === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  Fechar Operação
                </Button>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
      {selectedOperation && selectedOperation.status > 0 && (
        <Box marginRight={2} marginBottom={2} marginLeft={2}>
          <Paper elevation={2}>
            <Stepper activeStep={selectedOperation.status - 1}>
              {steps.map((step) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box paddingRight={2} paddingBottom={2} paddingLeft={2}>
              {selectedOperation.status === 1 && (
                <MergeOrder data={mergeOrders} onNext={handleMerge} />
              )}
              {selectedOperation.status === 2 && <UploadRoute onNext={handleUploadRoute} />}
              {selectedOperation.status === 3 && <SendEmail onNext={handleSendEmails} />}
              {selectedOperation.status === 4 && (
                <CompleteOrder orders={orders} onNext={handleCompleteOrder} />
              )}
              {selectedOperation.status === 5 && (
                <Typography gutterBottom variant="h5" component="h2">
                  Operação Finalizada. Bom Trabalho =D
                </Typography>
              )}
            </Box>
          </Paper>
        </Box>
      )}
      <Box marginRight={2} marginBottom={2} marginLeft={2}>
        <Paper elevation={2}>
          <Box p={2}>
            <Typography gutterBottom variant="h5" component="h2">
              Notas dos Pedidos
            </Typography>
            {ordersWithNotes.map((order) => (
              <Box key={order.orderId} component="span" display="block">
                {`${order.orderId} - ${order.customer}: ${order.notes}`}
              </Box>
            ))}
          </Box>
        </Paper>
      </Box>
      <Dialog open={openConfirmDialog}>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja fechar a operação?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCloseOperationConfirm} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeliveryOperation.propTypes = {
  displaySnackbar: PropTypes.func.isRequired,
};

export default DeliveryOperation;
