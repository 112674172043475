import axios from 'axios';
import history from './context/historyContext';

class AxiosConfig {
  static setupInterceptors() {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

    axios.interceptors.request.use(
      (request) => {
        const authRequest = request;
        const user = JSON.parse(localStorage.getItem('user'));

        if (user && user.token) {
          authRequest.headers.Authorization = `Bearer ${user.token}`;
        }

        return authRequest;
      },
      (error) => Promise.reject(error),
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        if (!error.response) {
          return Promise.reject(
            new Error('Encontramos algum problema na rede e não podemos acessar o aplicativo'),
          );
        }

        if (error.response.status === 401) {
          localStorage.removeItem('user');
          history.push('/');
        }

        if (error.response.status === 500) {
          return Promise.reject(
            new Error('Ocorreu um erro inesperado. Por favor, contate o administrador.'),
          );
        }

        const customError = {
          ...error.response.data,
          status: error.response.status,
        };

        return Promise.reject(customError);
      },
    );
  }
}

export default AxiosConfig;
