import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

function getColumnSettings(fair, data) {
  return [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'sku',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'brand',
      label: 'Marca',
      options: {
        display: !fair,
        filter: !fair,
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: false,
      },
    },
    {
      name: 'wednesdaySupplier',
      label: 'Fornecedor Quarta',
      options: {
        display: fair,
        filter: fair,
      },
    },
    {
      name: 'saturdaySupplier',
      label: 'Fornecedor Sábado',
      options: {
        display: fair,
        filter: fair,
      },
    },
    {
      name: 'measurement',
      label: 'Medida',
      options: {
        display: !fair,
        filter: !fair,
        searchable: false,
        customBodyRender: (v) => (v === 'Kilogram' ? 'Kilograma' : 'Unidade'),
        customFilterListOptions: {
          render: (v) => `Medida: ${v === 'Kilogram' ? 'Kilograma' : 'Unidade'}`,
        },
      },
    },
    {
      name: 'minWeight',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'maxWeight',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'stockQuantity',
      label: 'Estoque',
      options: {
        display: !fair,
        filter: !fair,
        searchable: false,
        customBodyRenderLite: (index) => {
          if (data[index].stockQuantity) {
            return data[index].measurement === 'Kilogram'
              ? `${data[index].stockQuantity.toLocaleString('pt-BR')} Kg`
              : `${data[index].stockQuantity.toLocaleString('pt-BR')} Un`;
          }
          return '';
        },
      },
    },
    {
      name: 'replenishStockTo',
      label: 'Pedido Extra',
      options: {
        display: !fair,
        filter: !fair,
        searchable: false,
        customBodyRenderLite: (index) => {
          if (data[index].replenishStockTo) {
            return data[index].measurement === 'Kilogram'
              ? `${data[index].replenishStockTo.toLocaleString('pt-BR')} Kg -> ${
                  data[index].replenishStockWeekday
                }`
              : `${data[index].replenishStockTo.toLocaleString('pt-BR')} Un -> ${
                  data[index].replenishStockWeekday
                }`;
          }
          return '';
        },
        filterOptions: {
          names: ['Sim', 'Não'],
          logic(value, filterVal) {
            const show =
              (filterVal.indexOf('Sim') >= 0 && !!value) ||
              (filterVal.indexOf('Não') >= 0 && !value);
            return !show;
          },
        },
        customFilterListOptions: {
          render: (v) => `Pedido Extra: ${v}`,
        },
      },
      customFilterListOptions: {
        render: (v) => `Estoque: ${v}`,
      },
    },
    {
      name: 'minStockOnWednesday',
      label: 'Pedido Extra na Sexta',
      options: {
        display: !fair,
        filter: !fair,
        searchable: false,
        customBodyRenderLite: (index) => {
          if (data[index].minStockOnWednesday) {
            return data[index].measurement === 'Kilogram'
              ? `${data[index].minStockOnWednesday.toLocaleString('pt-BR')} Kg`
              : `${data[index].minStockOnWednesday.toLocaleString('pt-BR')} Un`;
          }
          return '';
        },
        filterOptions: {
          names: ['Sim', 'Não'],
          logic(value, filterVal) {
            const show =
              (filterVal.indexOf('Sim') >= 0 && !!value) ||
              (filterVal.indexOf('Não') >= 0 && !value);
            return !show;
          },
        },
        customFilterListOptions: {
          render: (v) => `Pedido Mínimo para Sexta: ${v}`,
        },
      },
    },
    {
      name: 'autoUpdateStock',
      label: 'Atualizar Estoque Automático',
      options: {
        display: !fair,
        filter: !fair,
        searchable: false,
        filterOptions: {
          renderValue: (v) => (v ? 'Sim' : 'Não'),
        },
        customBodyRenderLite: (index) => (data[index].autoUpdateStock ? <CheckIcon /> : <></>),
        customFilterListOptions: {
          render: (v) => `Atualização de Estoque Automático: ${v ? 'Sim' : 'Não'}`,
        },
      },
    },
    {
      name: 'ignoreStockQuantity',
      label: 'Controle Externo',
      options: {
        display: !fair,
        filter: !fair,
        searchable: false,
        filterOptions: {
          renderValue: (v) => (v ? 'Sim' : 'Não'),
        },
        customBodyRenderLite: (index) => (data[index].ignoreStockQuantity ? <CheckIcon /> : <></>),
        customFilterListOptions: {
          render: (v) => `Controle Externo: ${v ? 'Sim' : 'Não'}`,
        },
      },
    },
    {
      name: 'order',
      label: 'Ordem no Mapa',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'isActive',
      label: 'Ativo',
      options: {
        filterList: [true],
        searchable: false,
        filterOptions: {
          renderValue: (v) => (v ? 'Sim' : 'Não'),
        },
        customBodyRenderLite: (index) => (data[index].isActive ? <CheckIcon /> : <></>),
        customFilterListOptions: {
          render: (v) => `Ativo: ${v ? 'Sim' : 'Não'}`,
        },
      },
    },
  ];
}

export default getColumnSettings;
