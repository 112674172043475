import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../TextField';
import mergeFormikProps from '../mergeFormikProps';

class CurrencyMask extends React.PureComponent {
  render() {
    const { inputRef, onChange, name, min, max, ...restProps } = this.props;

    const isAllowed = (values) =>
      (values.floatValue >= min && values.floatValue <= max) || values.formattedValue === '';

    const onValueChange = (values) => {
      onChange({
        target: {
          value: values.floatValue,
          name,
        },
      });
    };

    return (
      <NumberFormat
        getInputRef={inputRef}
        onValueChange={onValueChange}
        isAllowed={isAllowed}
        decimalSeparator=","
        prefix="R$ "
        decimalScale={2}
        fixedDecimalScale
        name={name}
        {...restProps}
      />
    );
  }
}

CurrencyMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CurrencyMask.defaultProps = {
  max: 999999999.99,
  min: 0,
};

const CurrencyField = ({ min, max, textAlign, value, InputProps, ...restProps }) => (
  <TextField
    InputProps={{
      inputComponent: CurrencyMask,
      inputProps: {
        min,
        max,
        style: { textAlign },
      },
      ...InputProps,
    }}
    value={value === null ? '' : value}
    {...restProps}
  />
);

CurrencyField.propTypes = {
  InputProps: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  readOnly: PropTypes.bool,
  textAlign: PropTypes.string,
  value: PropTypes.number,
};

CurrencyField.defaultProps = {
  InputProps: null,
  max: 999999999.99,
  min: 0,
  readOnly: false,
  textAlign: null,
  value: null,
};

export default mergeFormikProps(CurrencyField);
