import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

function MergeOrder({ data, onNext }) {
  const [selected, setSelected] = React.useState([]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      {data.length === 0 && (
        <Typography gutterBottom variant="h5" component="h2">
          Nenhum cliente tem mais de um pedido.
        </Typography>
      )}
      {data.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Pedido</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  const orderIds = row.map((r) => r.orderId).join(',');
                  const isItemSelected = isSelected(orderIds);
                  const labelId = `enhanced-table-checkbox-${orderIds}`;

                  return (
                    <TableRow key={row.name} onClick={(event) => handleClick(event, orderIds)}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.map((r) => (
                          <>
                            {`${r.orderId} - ${r.customer}, ${r.address}`}
                            <br />
                          </>
                        ))}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={() => onNext(selected)}>
          {data.length === 0 ? 'Próximo' : 'Juntar Selecionados e Próximo'}
        </Button>
      </Box>
    </>
  );
}

MergeOrder.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      customer: PropTypes.string.isRequired,
      orderId: PropTypes.number.isRequired,
    }),
  ),
  onNext: PropTypes.func.isRequired,
};

MergeOrder.defaultProps = {
  data: [],
};

export default MergeOrder;
