import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

function UploadRoute({ onNext }) {
  const [files, setFiles] = React.useState([]);

  return (
    <>
      <DropzoneArea
        acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        filesLimit={2}
        dropzoneText="Arraste e solte um arquivo aqui ou clique"
        onChange={setFiles}
      />
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={() => onNext(files)}>
          Próximo
        </Button>
      </Box>
    </>
  );
}

UploadRoute.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default UploadRoute;
