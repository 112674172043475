import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Tab, Tabs } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import CustomToolbar from './CustomToolbar';
import getColumnSettings from './columnSettings';
import getTableOptions from './tableOptions';
import filesaver from '../../utils/filesaver';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Products() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await axios.get('product');
    setData(response.data);
    setLoading(false);
  };

  useEffect(() => fetchData(), []);

  const downloadProducts = async () => {
    setLoading(true);

    const response = await axios.get('product/download', { responseType: 'blob' });

    setLoading(false);
    filesaver(response);
  };

  const uploadProducts = async (files) => {
    setOpenUploadDialog(false);
    setLoading(true);

    const formData = new FormData();
    files.forEach((f) => formData.append('file', f));

    const response = await axios.post('product/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setData(response.data);
    setLoading(false);
  };

  const handleOpen = () => setOpenUploadDialog(true);
  const handleClose = () => setOpenUploadDialog(false);

  const orderBy = () => {
    if (filter !== 'fair') return 'brand';

    const day = new Date(Date.now()).getDay();

    if ([0, 1, 2, 6].includes(day)) return 'wednesdaySupplier';

    return 'saturdaySupplier';
  };

  const options = getTableOptions(orderBy, () => (
    <CustomToolbar
      downLoadTooltip="Download"
      downloadOnClick={downloadProducts}
      uploadTooltip="Upload"
      uploadOnClick={handleOpen}
    />
  ));

  let filteredData = [];

  if (filter === 'all') {
    filteredData = data;
  } else if (filter === 'fair') {
    filteredData = data.filter((d) => d.fair);
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Tabs value={filter} onChange={(e, v) => setFilter(v)} aria-label="simple tabs example">
        <Tab label="Todos" value="all" />
        <Tab label="Feira" value="fair" />
      </Tabs>
      <MUIDataTable
        title="Produtos"
        data={filteredData}
        columns={getColumnSettings(filter === 'fair', filteredData)}
        options={options}
      />
      <DropzoneDialog
        open={openUploadDialog}
        onSave={uploadProducts}
        acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        onClose={handleClose}
        filesLimit={1}
        dialogTitle="Upload de arquivo"
        cancelButtonText="CANCELAR"
        submitButtonText="ENVIAR"
        dropzoneText="Arraste e solte um arquivo aqui ou clique"
      />
    </>
  );
}

export default Products;
