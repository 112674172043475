import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Email from '@material-ui/icons/Email';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Lock from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';

import { InputField } from '../../components/inputs';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginForm = ({ location, isSubmitting, status, errors, touched }) => {
  const { from } = location.state || { from: { pathname: '/' } };
  const classes = useStyles();

  if (status && status.redirect) {
    return <Redirect to={from} />;
  }

  const showError = {
    email: Boolean(errors.email && touched.email),
    password: Boolean(errors.password && touched.password),
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} />
        <Typography component="h1" variant="h5" color="primary">
          Local Farmers Admin
        </Typography>
        <Form className={classes.form} autoComplete="off" noValidate>
          <FormControl error={showError.email} fullWidth required>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Field
              name="email"
              component={InputField}
              endAdornment={
                <InputAdornment position="end">
                  <Email color={showError.email ? 'error' : undefined} />
                </InputAdornment>
              }
              disabled={isSubmitting}
            />
          </FormControl>
          <FormControl error={showError.password} fullWidth required>
            <InputLabel htmlFor="password">Senha</InputLabel>
            <Field
              name="password"
              component={InputField}
              type="password"
              endAdornment={
                <InputAdornment position="end">
                  <Lock color={showError.password ? 'error' : undefined} />
                </InputAdornment>
              }
              disabled={isSubmitting}
            />
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={classes.submit}
            disabled={isSubmitting}
            fullWidth
          >
            {isSubmitting ? <CircularProgress size={25} thickness={2} /> : 'Entrar'}
          </Button>
        </Form>
      </div>
    </Container>
  );
};

LoginForm.propTypes = {
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  status: PropTypes.shape({
    redirect: PropTypes.bool.isRequired,
  }),
  touched: PropTypes.object.isRequired,
};

LoginForm.defaultProps = {
  status: null,
};

const Login = withFormik({
  validationSchema: Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(5).required(),
  }),
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  handleSubmit: (values, { setStatus, setSubmitting }) => {
    axios
      .post('user/authenticate', values)
      .then((user) => {
        const mergedUser = {
          ...JSON.parse(localStorage.getItem('user')),
          ...user.data,
        };

        localStorage.setItem('user', JSON.stringify(mergedUser));
        setStatus({ redirect: true });
      })
      .catch(() => setSubmitting(false));
  },
})(LoginForm);

export default Login;
