import React from 'react';
import { Route } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';

import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import MenuIcon from '@material-ui/icons/Menu';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import Snackbar from '../../components/Snackbar';

import SoldItems from '../../routes/SoldItems';
import DeliveryOperation from '../../routes/DeliveryOperation';
import Subscriptions from '../../routes/Subscriptions';
import Reports from '../../routes/Reports';
import Emails from '../../routes/Emails';
import Products from '../../routes/Products';

import ListItemLink from './ListItemLink';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}));

function BaseLayout() {
  const classes = useStyles();
  const theme = useTheme();

  const queueRef = React.useRef([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    variant: 'error',
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function processSnackbarQueue() {
    if (queueRef.current.length > 0) {
      setSnackbar({
        ...queueRef.current.shift(),
        open: true,
      });
    }
  }

  function displaySnackbar(message, variant) {
    let messages = message;
    if (!Array.isArray(message)) {
      messages = [message];
    }

    messages.forEach((msg) => {
      queueRef.current.push({
        message: msg,
        variant: variant || 'error',
        key: new Date().getTime(),
      });
    });

    if (snackbar.open) {
      setSnackbar({ ...snackbar, open: false });
    } else {
      processSnackbarQueue();
    }
  }

  function handleSnackbarClose() {
    setSnackbar({ ...snackbar, open: false });
  }

  function handleSnackbarExited() {
    processSnackbarQueue();
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        <ListItemLink primary="Operação" to="/deliveryoperation" icon={<StorefrontIcon />} />
        <ListItemLink primary="Itens Vendidos" to="/solditems" icon={<ShoppingBasketIcon />} />
        <ListItemLink primary="Assinaturas" to="/subscriptions" icon={<CardMembershipIcon />} />
        <ListItemLink primary="Relatórios" to="/reports" icon={<DescriptionIcon />} />
        <ListItemLink primary="Produtos" to="/products" icon={<LocalOfferIcon />} />
        <ListItemLink primary="E-Mails" to="/emails" icon={<EmailIcon />} />
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Route
          path="/deliveryoperation"
          render={(props) => <DeliveryOperation displaySnackbar={displaySnackbar} {...props} />}
        />
        <Route
          path="/solditems"
          render={(props) => <SoldItems displaySnackbar={displaySnackbar} {...props} />}
        />
        <Route
          path="/subscriptions"
          crender={(props) => <Subscriptions displaySnackbar={displaySnackbar} {...props} />}
        />
        <Route
          path="/reports"
          render={(props) => <Reports displaySnackbar={displaySnackbar} {...props} />}
        />
        <Route
          path="/products"
          render={(props) => <Products displaySnackbar={displaySnackbar} {...props} />}
        />
        <Route
          path="/emails"
          render={(props) => <Emails displaySnackbar={displaySnackbar} {...props} />}
        />
      </main>
      <Snackbar
        key={snackbar.key}
        message={snackbar.message}
        open={snackbar.open}
        variant={snackbar.variant}
        onClose={handleSnackbarClose}
        onExited={handleSnackbarExited}
      />
    </div>
  );
}

export default BaseLayout;
