import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}));

function Emails() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [connection, setConnection] = useState(null);
  const dataRef = useRef(null);

  dataRef.current = data;

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get('schedule');

      setData(result.data);
      setLoading(false);
    };

    fetchData();

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BASE_URL}hubs/schedule`)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  const onReceiveStatus = (id) => {
    console.log(`Email sent!${id}`);
    const currentData = [...dataRef.current];

    const newData = currentData.map((d) => {
      const s = d;
      if (d.id === id) s.status = 'Enviado';

      return s;
    });

    setData(newData);
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log('Connected!');

          connection.on('Status', onReceiveStatus);
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  const sendEmail = async () => {
    await axios.get('schedule/sendemails');
  };

  const config = [
    { id: 'orderId', label: 'Pedido' },
    { id: 'customerName', label: 'Cliente' },
    { id: 'email', label: 'E-Mail' },
    { id: 'status', label: 'Status' },
  ];

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                {config.map((headCell) => (
                  <TableCell key={headCell.id} align="left" padding="none">
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  {config.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'}>
                      {row[headCell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" color="primary" onClick={() => sendEmail()}>
          Disparar E-mails
        </Button>
      </Paper>
    </div>
  );
}

export default Emails;
