import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

function ProtectedRoute({ component: Component, ...rest }) {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAuthenticate = user?.token != null;

  return (
    <Route
      {...rest}
      render={(innerProps) => {
        if (isAuthenticate) {
          return <Component {...innerProps} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: innerProps.location },
            }}
          />
        );
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

export default ProtectedRoute;
