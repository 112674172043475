import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
} from '@material-ui/core';

import EnhancedTable from '../../components/EnhancedTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const tableConfig = [
  { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantidade' },
  { id: 'itemName', numeric: false, disablePadding: true, label: 'Produto' },
  { id: 'brandName', numeric: false, disablePadding: true, label: 'Marca' },
];

function SoldItems() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = React.useState('all');
  const [errorMessages, setErrorMessages] = useState([]);

  const handleFilterChange = (event, newValue) => {
    setFilter(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get('SoldItems');

      if (!result.data.success) {
        setErrorMessages(result.data.errors);
      } else {
        setData(result.data.result);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  let filteredData = [];
  let title = '';

  if (filter === 'all') {
    filteredData = data;
    title = 'Todos os Items';
  } else if (filter === 'toWeight') {
    filteredData = data.filter((d) => d.sku.includes('-PES-'));
    title = 'Items para Pesagem';
  } else if (filter === 'grocery') {
    filteredData = data.filter((d) => d.sku.includes('-MRC-'));
    title = 'Items de Mercearia (Estante)';
  } else if (filter === 'fridge') {
    filteredData = data.filter((d) => d.sku.includes('-GLD-') || d.sku.includes('-ISO-'));
    title = 'Items da Geladeira e Freezer';
  } else if (filter === 'others') {
    filteredData = data.filter((d) => d.sku.includes('-HRT-') || d.sku.includes('-MSC-'));
    title = 'Outros Items';
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.root}>
        <Tabs value={filter} onChange={handleFilterChange} aria-label="simple tabs example">
          <Tab label="Todos" value="all" />
          <Tab label="Pesagem" value="toWeight" />
          <Tab label="Mercearia" value="grocery" />
          <Tab label="Geladeira" value="fridge" />
          <Tab label="Outros" value="others" />
        </Tabs>
        <div>
          <EnhancedTable title={title} rows={filteredData} config={tableConfig} />
        </div>
      </div>
      <Dialog open={errorMessages.length > 0} onClose={() => setErrorMessages([])}>
        <DialogTitle id="alert-dialog-title">Ops.. Ocorreu um erro :(</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List>
              {errorMessages.map((msg) => (
                <ListItem key={msg}>
                  <ListItemText primary={msg} />
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SoldItems;
