import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const CustomizedSnackbar = (props) => {
  const { classes, message, onClose, onExited, open, variant, key } = props;

  const Icon = variantIcon[variant];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Snackbar
      key={key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
      onExited={onExited}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

CustomizedSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  key: PropTypes.number,
  message: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['success', 'error', 'info']).isRequired,
};

CustomizedSnackbar.defaultProps = {
  key: null,
  message: '',
  open: false,
};

export default withStyles(styles)(CustomizedSnackbar);
