import React, { useState } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import filesaver from '../../utils/filesaver';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Reports() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const handleDownloadClick = async (url) => {
    setLoading(true);

    const downloadResponse = await axios.get(url, {
      responseType: 'blob',
    });

    filesaver(downloadResponse);

    setLoading(false);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Relatório de Estoque
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => handleDownloadClick('Report/StockReport')}>
                Baixar
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Relatório Mensal de Compras Produtores
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => handleDownloadClick('Report/MonthlyPurchaseReport')}
              >
                Baixar
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Coming Soon
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Baixar</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Coming Soon
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Baixar</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Reports;
