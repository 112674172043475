import React from 'react';
import PropTypes from 'prop-types';
import MuiInput from '@material-ui/core/Input';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import mergeFormikProps from '../mergeFormikProps';

const InputField = ({ helperText, ...restProps }) => (
  <>
    <MuiInput {...restProps} />
    {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
  </>
);

InputField.propTypes = {
  helperText: PropTypes.string,
};

InputField.defaultProps = {
  helperText: undefined,
};

export default mergeFormikProps(InputField);
