import React from 'react';
import PropTypes from 'prop-types';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import sortBy from 'lodash/sortBy';

const OrderStatusCompleted = 4;

function CompleteOrder({ orders, onNext }) {
  const [selected, setSelected] = React.useState([]);

  const groupedByDriver = groupBy(orders, (o) => o.route);

  let data = [];

  for (const [key, value] of Object.entries(groupedByDriver)) {
    data.push({
      id: key === 'null' ? 'Sem Rota' : key,
      name: key === 'null' ? 'Sem Rota' : key,
      date: value[0].deliveryDate,
      stops: value,
    });
  }

  data = sortBy(data, (r) => [r.date, r.name]);

  const handleSelectAllClick = (event, stops) => {
    const selectedIds = stops
      .filter((r) => r.status !== OrderStatusCompleted)
      .map((s) => s.orderId);

    let newSelecteds = selected.filter((orderId) => !selectedIds.includes(orderId));

    if (event.target.checked) {
      newSelecteds = [...selected, ...selectedIds];
    }

    setSelected(newSelecteds);
  };

  const handleSelectClick = (event, stop) => {
    let newSelecteds = selected.filter((orderId) => stop.orderId !== orderId);

    if (event.target.checked) {
      newSelecteds = [...selected, stop.orderId];
    }

    setSelected(newSelecteds);
  };

  const handleNext = () => {
    onNext(selected);
    setSelected([]);
  };

  return (
    <>
      <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
        {data.map((route) => {
          const routeSelected = intersection(
            route.stops.map((s) => s.orderId),
            selected,
          );

          const sortedStops = sortBy(route.stops, (r) => r.stopNumber);
          const completedOrders = route.stops.filter((r) => r.status === OrderStatusCompleted);
          const checkedOrders = routeSelected.length + completedOrders.length;

          return (
            <TreeItem
              key={route.id}
              nodeId={route.id}
              label={
                <FormControlLabel
                  control={
                    <Checkbox
                      indeterminate={checkedOrders > 0 && checkedOrders < route.stops.length}
                      checked={route.stops.length > 0 && checkedOrders === route.stops.length}
                      disabled={completedOrders.length === route.stops.length}
                      onChange={(event) => handleSelectAllClick(event, route.stops)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label={<>{route.name}</>}
                  key={route.id}
                />
              }
            >
              {sortedStops.map((stop) => {
                let label = stop.stopNumber ? `${String(stop.stopNumber).padStart(2, '0')} - ` : '';
                label = `${label} (${stop.orderId}) ${stop.customer}`;

                return (
                  <TreeItem
                    key={stop.orderId}
                    nodeId={stop.orderId}
                    label={
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selected.includes(stop.orderId) ||
                              stop.status === OrderStatusCompleted
                            }
                            disabled={stop.status === OrderStatusCompleted}
                            onChange={(event) => handleSelectClick(event, stop)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        }
                        label={label}
                        key={stop.orderId}
                      />
                    }
                  />
                );
              })}
            </TreeItem>
          );
        })}
      </TreeView>
      <Button onClick={handleNext} color="primary" variant="contained">
        Concluir Selecionados
      </Button>
    </>
  );
}

CompleteOrder.propTypes = {
  onNext: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      customer: PropTypes.string.isRequired,
      orderId: PropTypes.number.isRequired,
      stopNumber: PropTypes.number.isRequired,
    }),
  ),
};

CompleteOrder.defaultProps = {
  orders: [],
};

export default CompleteOrder;
