import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function SendEmail({ onNext }) {
  return (
    <>
      <Typography gutterBottom variant="h5">
        Geramos as rotas, agora precisamos informar nossos cliente.
      </Typography>
      <Typography gutterBottom variant="subtitle1">
        Clique em próximo para enviar os emails com a previsão de entrega.
      </Typography>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={onNext}>
          Próximo
        </Button>
      </Box>
    </>
  );
}

SendEmail.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default SendEmail;
